import axios from "@/axios.js";

export default {
  /**
   * Retrieve updated userInfo
   * @param {*} me Vue's `this` instance
   */
  GetMe() {
    return new Promise((resolve, reject) => {
      axios
        .get("me")
        .then((response) => {
          if (!response) return;

          const UI = response.data.data;
          if (![undefined, "undefined", null, "null"].includes(UI.nexlaw_credits)) localStorage.setItem("userInfo", JSON.stringify(UI));

          resolve(response);
        })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Retrieve campaign list
   * @param {*} args Queries to filter the list
   */
  GetCampaigns(args = null) {
    var API = `nexlaw`;
    if (args) {
      API += `?` + Object
        .entries(args)
        .map(([k, v]) => `${k}=${v}`)
        .join(`&`);
    }

    return new Promise((resolve, reject) => {
      axios
        .get(API)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },
  /**
   * Archieve campaign list
   * @param {*} args Queries to filter the list
   */
  GetArchivedCampaigns(args = null) {
    let API = `nexlaw?archived=1`;

    if (args) {
      const queryString = Object.entries(args)
        .map(([k, v]) => {
          if (Array.isArray(v)) {
            // If it's an array, format each element with `[]` for multi types
            return v.map(item => `${k}[]=${item}`).join('&');
          } else {
            // If it's a single type, format as a normal key-value pair
            return `${k}=${v}`;
          }
        })
        .join('&');

      // Append the query string to the API URL
      API += `&` + queryString;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(API)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

    /**
   * Archieve campaign list
   * @param {*} args Queries to filter the list
   */
  GetBookmarkedCampaigns(args = null) {
    let API = `nexlaw?bookmarked=1`;

    if (args) {
      const queryString = Object.entries(args)
        .map(([k, v]) => {
          if (Array.isArray(v)) {
            // If it's an array, format each element with `[]` for multi types
            return v.map(item => `${k}[]=${item}`).join('&');
          } else {
            // If it's a single type, format as a normal key-value pair
            return `${k}=${v}`;
          }
        })
        .join('&');

      // Append the query string to the API URL
      API += `&` + queryString;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(API)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },
  

  /**
   * Permanently delete a campaign
   * @param {*} id Campaign ID
   */
  DeleteCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`nexlaw/${id}/delete`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Bookmark campaign
   * @param {*} id Campaign ID
   */
      BookmarkCampaign(id) {
        return new Promise((resolve, reject) => {
          axios
            .post(`nexlaw/${id}/bookmark`)
            .then((response) => { resolve(response); })
            .catch((error) => { reject(error); });
        });
      },
      

  /**
   * Move campaign into `Archived Campaigns`
   * @param {*} id Campaign ID
   */
  ArchiveCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/${id}/archive`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Unarchive campaign
   * @param {*} id Campaign ID
   */
  UnarchiveCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/${id}/unarchive`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Unbookmark campaign
   * @param {*} id Campaign ID
   */
  UnbookmarkCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/${id}/unbookmark`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  ActivityLog(args = null) {
    let API = `nexlaw`;

    if (args) {
      const queryString = Object.entries(args)
        .map(([k, v]) => Array.isArray(v) 
          ? v.map(item => `${k}[]=${item}`).join('&') 
          : `${k}=${v}`
        )
        .join('&');

      API += `?` + queryString;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(API)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },
}