<template>
  <div id="Header">
    <div>
      <h3 v-if="!subtitle">{{ title }}</h3>
      <template v-else>
        <span class="material-symbols-rounded main">{{ icon }}</span>
        <h3>{{ title }}</h3>
      </template>

      <span v-if="subtitle" class="divider"></span>
      <span v-if="!subtitle" class="material-symbols-rounded sub">{{ icon }}</span>
      <p :class="subtitle && 'black'">{{ subtitle ?? title }}</p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
    subtitle: { type: String },
    icon: { type: String },
  }
}
</script>

<style scoped>
h3, h5, p {
  margin-bottom: 0px;
}

span.main {
  margin-right: 2px;
  font-size: 24px;
}

#Header {
  margin: -20px -20px 0px -20px;
  padding: 16px 20px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }

  & button {
    margin-left: 50px;
  }
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

span.sub {
  margin-right: 2px;
  font-size: 18px;
  color: var(--dark-grey);
}

p {
  font-weight: 500;
  color: var(--dark-grey) !important;
}

.black {
  color: var(--dark-grey) !important;
}
</style>